<template>
  <section class="t3-ce-container-2-columns">
    <div class="cols">
      <div :class="`cols__${firstColumn}`">
        <T3Renderer :content="first" />
      </div>
      <div :class="`cols__${secondColumn}`">
        <T3Renderer :content="second" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { T3Appearance, T3CeBaseProps, T3ContentElement } from '@t3headless/nuxt-typo3'
import { computed } from 'vue'

const props = defineProps<{
  appearance: T3Appearance
  first: Array<T3ContentElement<T3CeBaseProps>>
  second: Array<T3ContentElement<T3CeBaseProps>>
}>()

const columns = computed(() => {
  switch (props.appearance?.layout) {
    case 'layout-1':
      return ['two-fifths', 'three-fifths']
    case 'layout-2':
      return ['three-fifths', 'two-fifths']
    default:
      return ['first', 'later']
  }
})

const [firstColumn, secondColumn] = columns.value
</script>
